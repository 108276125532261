import { createAction, handleActions } from 'redux-actions';
import { successAction, failAction } from 'utils/state-helpers';
import { CERTIFICATES_GET, CERTIFICATES_GET_SINGLE } from './constants';

export const fetchCertificates = createAction(CERTIFICATES_GET);
export const fetchCertificatesSuccess = createAction(successAction(CERTIFICATES_GET));
export const fetchCertificatesFail = createAction(failAction(CERTIFICATES_GET));

export const fetchSingleCertificate = createAction(CERTIFICATES_GET_SINGLE);
export const fetchSingleCertificateSuccess = createAction(
  successAction(CERTIFICATES_GET_SINGLE),
);
export const fetchSingleCertificateFail = createAction(
  failAction(CERTIFICATES_GET_SINGLE),
);

const initialState = {
  fetching: false,
  fetchingSingle: false,
  adding: false,
  updating: false,
  deleting: false,
  error: null,
  certificates: [],
  currentCertificate: null,
};

export const reducer = handleActions(
  {
    [CERTIFICATES_GET]: (state) => ({
      ...state,
      fetching: true,
    }),

    [successAction(CERTIFICATES_GET)]: (state, action) => ({
      ...state,
      fetching: false,
      certificates: action.payload,
    }),

    [CERTIFICATES_GET_SINGLE]: (state) => ({
      ...state,
      fetchingSingle: true,
      error: null,
    }),

    [failAction(CERTIFICATES_GET_SINGLE)]: (state, action) => ({
      ...state,
      fetchingSingle: false,
      error: 'Failed to load certificate data',
    }),

    [successAction(CERTIFICATES_GET_SINGLE)]: (state, action) => ({
      ...state,
      fetchingSingle: false,
      currentCertificate: action.payload,
    }),
  },
  initialState,
);
