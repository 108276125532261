import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import WarningIcon from 'design/Icons/WarningIcon';
import CheckIcon from 'design/Icons/CheckIcon';

export type DialogType = 'warning' | 'success';

interface ConfirmationDialogProps {
  open: boolean;
  title?: string;
  message: ReactNode;
  onDismiss: () => void;
  onConfirm: () => void;
  confirmBtnText?: string;
  cancelBtnText?: string;
  type?: DialogType;
}

const ConfirmationDialog = ({
  title,
  open = false,
  onDismiss,
  onConfirm,
  message,
  confirmBtnText = 'Confirm',
  cancelBtnText = 'Cancel',
  type = 'warning',
}: ConfirmationDialogProps) => {
  if (!open) return null;

  return createPortal(
    <div className="alert-confirmation-area">
      <div
        className={cn('alert alert-confirmation alert-dismissible fade show', {
          'alert-danger': type === 'warning',
          'alert-info': type === 'success',
        })}
        role="alert"
      >
        <div className="alert-icon">
          <span>{type === 'warning' ? <WarningIcon /> : <CheckIcon fill="white" />}</span>
        </div>

        <div className="alert-message">{message}</div>
        <div className="alert-actions">
          <button
            type="button"
            className={cn('btn', {
              'btn-danger': type === 'warning',
              'btn-primary': type === 'success',
            })}
            onClick={onConfirm}
          >
            {confirmBtnText}
          </button>
          <button
            type="button"
            className="btn"
            data-dismiss="alert"
            aria-label="Close"
            onClick={onDismiss}
          >
            {cancelBtnText}
          </button>
        </div>
      </div>
    </div>,
    document.getElementById('alert-confirmation-area-root')!,
  );
};

export default ConfirmationDialog;
