export const loadStonlyWidget = () => {
  const idScript = document.createElement('script');
  const widgeteScript = document.createElement('script');
  idScript.defer = true;
  widgeteScript.defer = true;
  idScript.innerHTML = "window.STONLY_WID = '3089665b-b2c8-11ed-871a-0a52ff1ec764'";
  widgeteScript.innerHTML = `!(function (s, t, o, n, l, y, w, g) {
    s.StonlyWidget ||
        (((w = s.StonlyWidget = function () {
        w._api ? w._api.apply(w, arguments) : w.queue.push(arguments);
        }).scriptPath = n),
        (w.queue = []),
        ((y = t.createElement(o)).async = !0),
        (g = new XMLHttpRequest()).open('GET', n + 'version?v=' + Date.now(), !0),
        (g.onreadystatechange = function () {
        4 === g.readyState &&
            ((y.src =
            n +
            'stonly-widget.js?v=' +
            (200 === g.status ? g.responseText : Date.now())),
            (l = t.getElementsByTagName(o)[0]).parentNode.insertBefore(y, l));
        }),
        g.send());
    })(window, document, 'script', 'https://stonly.com/js/widget/v2/');`;
  document.head.appendChild(idScript);
  document.head.appendChild(widgeteScript);
};
