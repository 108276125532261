import { AUTH_SIGNOUT_USER } from 'store/modules/auth';
import { toast } from 'react-toastify';
import { Middleware } from 'redux';
import { RootState } from 'store';

const authMiddleware: Middleware<{}, RootState> = (store) => (next) => (action) => {
  const { status, message } = action.payload || {};

  // The message comparison hack is to accomodate the stats API which is non-compliant on token expiration
  // HTTP status code
  if (status === 401 || message === 'Access token is invalid') {
    store.dispatch({
      type: AUTH_SIGNOUT_USER,
    });
  } else if (status === 403) {
    toast.error('You lack enough privileges to perform the selected operation');
  }

  return next(action);
};

export default authMiddleware;
