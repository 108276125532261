import { RootState } from 'store';
import { Certificate } from 'models/Certificate';

export const selectCertificatesFetching = (state: RootState) =>
  state.certificates.fetching;

export const selectCertificatesFetchingSingle = (state: RootState) =>
  state.certificates.fetchingSingle;

export const selectCertificatesCurrent = (state: RootState) =>
  state.certificates.currentCertificate as Certificate | null;

export const selectCertificates = (state: RootState) =>
  state.certificates.certificates as Certificate[];
