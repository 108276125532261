import React from 'react';
import { Icon } from './Icon';

const CheckIcon = ({ fill, ...rest }: Icon) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={fill || 'currentColor'}
        fillRule="evenodd"
        d="M19.1561 6.34393C19.6146 6.8025 19.6146 7.54599 19.1561 8.00456L10.1144 17.0462C9.65584 17.5048 8.91235 17.5048 8.45378 17.0462L4.34393 12.9364C3.88536 12.4778 3.88536 11.7343 4.34393 11.2757C4.8025 10.8172 5.54599 10.8172 6.00456 11.2757L9.28409 14.5553L17.4954 6.34393C17.954 5.88536 18.6975 5.88536 19.1561 6.34393Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default CheckIcon;
