import { RootState } from 'store';

export const selectOriginsState = (state: RootState) => state.origins;

export const selectOriginsFetching = (state: RootState) => state.origins.fetching;

export const selectOriginsFetchingSingle = (state: RootState) =>
  state.origins.fetchingSingle;
export const selectOriginsCurrent = (state: RootState) => state.origins.currentOrigin;

export const selectOrigins = (state: RootState) => state.origins.origins;

export const selectOriginsAdding = (state: RootState) => state.origins.adding;
export const selectOriginsUpdating = (state: RootState) => state.origins.updating;
export const selectOriginsDeleting = (state: RootState) => state.origins.deleting;
export const selectOriginsError = (state: RootState) => state.origins.error;
