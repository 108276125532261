import { useCallback, useContext } from 'react';
import {
  ConfirmationDialogContext,
  DialogConfigProps,
} from './ConfirmationDialogProvider';

const useConfirmationDialog = () => {
  const { openDialog } = useContext(ConfirmationDialogContext);

  const getConfirmation = useCallback(
    (options: DialogConfigProps) =>
      new Promise((res: (value: boolean) => void) => {
        openDialog({ ...options, actionCallback: res });
      }),
    [],
  );

  return { getConfirmation };
};

export default useConfirmationDialog;
