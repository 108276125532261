import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import { ApiToken } from 'models/ApiToken';
import { RootState } from 'store';
import { apiGet } from 'utils/request';

export const fetchTokens = createAsyncThunk(
  'apiTokens/fetchTokens',
  async (serviceId?: string) => {
    const { data } = (await apiGet('/tokens?limit=1000', { action: 'getTokens' })) as {
      data: ApiToken[];
    };

    let tokens = data;

    if (serviceId) {
      tokens = data.filter(
        (t) =>
          t.permissions.includes('P_SERVICE_ALL') || t.services.includes(serviceId || ''),
      );
    }
    return { tokens };
  },
);

// export const revealToken = createAsyncThunk(
//   'apiTokens/revealToken',
//   async (id: string) => {
//     const data = await apiGet(`/tokens/${id}/reveal`, { action: 'revealToken' });
//     return data;
//   },
// );

export const fetchTokensForCodeSnippet = createAsyncThunk(
  'apiTokens/getTokensForCodeSnippet',
  async (serviceId: string) => {
    const { data } = (await apiGet('/tokens?limit=1000', { action: 'getTokens' })) as {
      data: ApiToken[];
    };
    const { token } = await apiGet(`/tokens/${data[0]._id}/reveal`, {
      action: 'revealToken',
    });

    const tokens = data.filter(
      (t) => t.permissions.includes('P_SERVICE_ALL') || t.services.includes(serviceId),
    );
    let selectedToken = null;
    if (tokens[0] && token) {
      selectedToken = {
        ...tokens[0],
        token,
      };
    }
    return {
      tokens,
      selectedToken,
    };
  },
);

interface State {
  selectedToken: ApiToken | null; // for service options code snippet
  data: ApiToken[];
  isLoading: boolean;
  error: SerializedError | null;
}

const initialState: State = {
  data: [],
  isLoading: false,
  error: null,
  selectedToken: null,
};

const apiTokensSlice = createSlice({
  name: 'apiTokens',
  initialState,
  reducers: {
    setSelectedToken(state, action: PayloadAction<ApiToken | null>) {
      state.selectedToken = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchTokens.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTokens.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.data = action.payload.tokens;
    });
    builder.addCase(fetchTokens.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    builder.addCase(fetchTokensForCodeSnippet.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTokensForCodeSnippet.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.data = action.payload.tokens;
      state.selectedToken = action.payload.selectedToken;
    });
    builder.addCase(fetchTokensForCodeSnippet.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const selectApiTokens = (state: RootState) => state.apiTokens.data;
export const selectSelectedToken = (state: RootState) => state.apiTokens.selectedToken;
export const selectApiTokensLoading = (state: RootState) => state.apiTokens.isLoading;
export const { setSelectedToken } = apiTokensSlice.actions;
export const reducer = apiTokensSlice.reducer;
