import { createAction, handleActions } from 'redux-actions';
import {
  AUTH_HIDE_MESSAGE,
  AUTH_ON_HIDE_LOADER,
  AUTH_ON_SHOW_LOADER,
  AUTH_SHOW_MESSAGE,
  AUTH_SIGNIN_USER_SUCCESS,
  AUTH_SIGNOUT_USER_SUCCESS,
  AUTH_SIGNIN_USER,
  AUTH_SIGNIN_USER_FROM_PORTAL,
  AUTH_SIGNOUT_USER,
  AUTH_IMPERSONATE,
} from './constants';
import { failAction, successAction } from '../../../utils/state-helpers';

/**
 * Action creators
 */

export const userSignIn = createAction(AUTH_SIGNIN_USER);

export const userSignInFromPortal = createAction(AUTH_SIGNIN_USER_FROM_PORTAL);

export const userSignOut = createAction(AUTH_SIGNOUT_USER);

export const userSignInSuccess = createAction(AUTH_SIGNIN_USER_SUCCESS);

export const userSignOutSuccess = createAction(AUTH_SIGNOUT_USER_SUCCESS);

export const showAuthMessage = createAction(AUTH_SHOW_MESSAGE);

export const showAuthLoader = createAction(AUTH_ON_SHOW_LOADER);

export const hideMessage = createAction(AUTH_HIDE_MESSAGE);

export const hideAuthLoader = createAction(AUTH_ON_HIDE_LOADER);

export const authImpersonate = createAction(AUTH_IMPERSONATE);
export const authImpersonateSuccess = createAction(successAction(AUTH_IMPERSONATE));
export const authImpersonateFail = createAction(failAction(AUTH_IMPERSONATE));

/**
 * Initial state
 */
const getInitialState = () => {
  let auth = sessionStorage.getItem('auth');

  if (auth) {
    auth = JSON.parse(auth);

    return {
      alertMessage: '',
      loader: false,
      showMessage: false,
      status: 'INIT',
      user: auth?.user,
    };
  } else {
    return {
      alertMessage: '',
      loader: false,
      showMessage: false,
      status: 'INIT',
      user: null,
    };
  }
};

/**
 * Reducer
 */
export const reducer = handleActions(
  {
    [AUTH_SIGNIN_USER_SUCCESS]: (state, { payload, type }) => ({
      ...state,
      user: payload,
      loader: false,
      status: type,
    }),

    [AUTH_SIGNOUT_USER_SUCCESS]: (state, { payload, type }) => ({
      ...state,
      user: null,
      loader: false,
      status: type,
    }),

    [AUTH_SHOW_MESSAGE]: (state, { payload, type }) => ({
      ...state,
      alertMessage: payload,
      loader: false,
      showMessage: true,
      status: type,
    }),

    [AUTH_HIDE_MESSAGE]: (state, { payload, type }) => ({
      ...state,
      alertMessage: '',
      loader: false,
      showMessage: false,
      status: type,
    }),

    [AUTH_ON_SHOW_LOADER]: (state, { payload, type }) => ({
      ...state,
      loader: true,
      status: type,
    }),

    [AUTH_ON_HIDE_LOADER]: (state, { payload, type }) => ({
      ...state,
      loader: false,
      status: type,
    }),

    [AUTH_IMPERSONATE]: (state) => ({
      ...state,
      impersonating: true,
    }),

    [failAction(AUTH_IMPERSONATE)]: (state) => ({
      ...state,
      impersonating: false,
    }),

    [successAction(AUTH_IMPERSONATE)]: (state) => ({
      ...state,
      impersonating: false,
    }),
  },
  getInitialState(),
);
