import React from 'react';
import { Icon } from './Icon';

const InfoLetterIcon = ({ fill = 'white', ...rest }: Icon) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={fill || 'currentColor'}
        d="M12.242 4C13.3443 4 14.1872 4.84292 14.1872 5.94521C14.1872 7.04749 13.3443 7.89041 12.242 7.89041C11.1397 7.89041 10.2968 7.04749 10.2968 5.94521C10.2968 4.84292 11.1397 4 12.242 4ZM15.484 17.6164C15.484 17.9406 15.2247 18.2 14.8356 18.2H9.6484C9.3242 18.2 9 18.0055 9 17.6164V16.3196C9 15.9954 9.25936 15.6064 9.6484 15.6064C9.9726 15.6064 10.2968 15.4119 10.2968 15.0228V12.4292C10.2968 12.105 10.0374 11.716 9.6484 11.716C9.3242 11.716 9 11.5215 9 11.1324V9.83562C9 9.51142 9.25936 9.12237 9.6484 9.12237H13.5388C13.863 9.12237 14.1872 9.44658 14.1872 9.83562V15.0228C14.1872 15.347 14.4466 15.6064 14.8356 15.6064C15.1598 15.6064 15.484 15.9306 15.484 16.3196V17.6164Z"
      />
    </svg>
  );
};

export default InfoLetterIcon;
