import {
  PayloadAction,
  SerializedError,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { Account } from 'models/Account';
import { RootState } from 'store';
import { apiGet, apiPut } from 'utils/request';
import { setAllServices } from '../services';

export const getAccount = createAsyncThunk('ui/getAccount', async (_, thunkApi) => {
  const account: Account = await apiGet('/accounts/me?responseType=full', {
    action: 'getAccount',
  });
  thunkApi.dispatch(
    setAllServices(account.services.filter((s) => s.status !== 'DELETED')),
  );
  return account;
});

export const updateAccount = createAsyncThunk(
  'ui/updateAccount',
  async (data: Partial<Account> | Record<string, string>, { rejectWithValue }) => {
    try {
      const account: Account = await apiPut(`/accounts/me`, data, {
        action: 'updateAccount',
      });
      return account;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

interface State {
  account: Account | null;
  isLoading: boolean;
  error: SerializedError | null;
  pending: boolean;
  updateError: SerializedError | null;
}

const initialState: State = {
  account: null,
  isLoading: false,
  error: null,
  pending: false,
  updateError: null,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAccount.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAccount.fulfilled, (state, action: PayloadAction<Account>) => {
      state.isLoading = false;
      state.error = null;
      state.account = action.payload;
    });
    builder.addCase(getAccount.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(updateAccount.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(updateAccount.fulfilled, (state, action: PayloadAction<Account>) => {
      state.pending = false;
      state.updateError = null;
      state.account = action.payload;
    });
    builder.addCase(updateAccount.rejected, (state, action) => {
      state.pending = false;
      state.updateError = action.error;
    });
  },
});

export const selectAccount = (state: RootState) => state.account.account;
export const selectIsAccountLoading = (state: RootState) => state.account.isLoading;

export const selectIsAccountPending = (state: RootState) => state.account.pending;

export const reducer = accountSlice.reducer;
