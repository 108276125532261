// Auth const
export const AUTH_HIDE_MESSAGE = 'HIDE_MESSAGE';
export const AUTH_SHOW_MESSAGE = 'SHOW_MESSAGE';
export const AUTH_ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const AUTH_ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const AUTH_SIGNIN_USER = 'SIGNIN_USER';
export const AUTH_SIGNIN_USER_FROM_PORTAL = 'SIGNIN_USER_FROM_PORTAL';
export const AUTH_SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const AUTH_SIGNOUT_USER = 'SIGNOUT_USER';
export const AUTH_SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';

export const AUTH_IMPERSONATE = 'AUTH_IMPERSONATE';
