import { createAction, handleActions } from 'redux-actions';
import {
  SETTING_HORIZONTAL_NAVIGATION,
  SETTING_CHANGE_NAVIGATION_STYLE,
  SETTING_DARK_THEME,
  SETTING_DRAWER_TYPE,
  SETTING_FIXED_DRAWER,
  SETTING_INSIDE_THE_HEADER,
  SETTING_THEME_COLOR,
  SETTING_TOGGLE_COLLAPSED_NAV,
  SETTING_WINDOW_WIDTH,
  SETTING_HORIZONTAL_MENU_POSITION,
  TOGGLE_HEADER_VISIBILITY,
} from './constants';

/**
 * Action creators
 */
export const toggleCollapsedNav = createAction(SETTING_TOGGLE_COLLAPSED_NAV);

export const setDrawerType = createAction(SETTING_DRAWER_TYPE);

export const updateWindowWidth = createAction(SETTING_WINDOW_WIDTH);

export const setThemeColor = createAction(SETTING_THEME_COLOR);

export const setDarkTheme = createAction(SETTING_DARK_THEME);

export const changeNavigationStyle = createAction(SETTING_CHANGE_NAVIGATION_STYLE);

export const setHorizontalMenuPosition = createAction(SETTING_HORIZONTAL_MENU_POSITION);

export const toggleHeaderVisibility = createAction(TOGGLE_HEADER_VISIBILITY);

/**
 * Initial state
 */
const initialState = {
  navCollapsed: true,
  drawerType: SETTING_FIXED_DRAWER,
  darkTheme: false,
  width: window.innerWidth,
  navigationStyle: SETTING_HORIZONTAL_NAVIGATION,
  horizontalNavPosition: SETTING_INSIDE_THE_HEADER,
  headerVisible: true,
};

/**
 * Reducer
 */
export const reducer = handleActions(
  {
    [SETTING_TOGGLE_COLLAPSED_NAV]: (state, { payload, type }) => ({
      ...state,
      navCollapsed: payload,
      status: type,
    }),

    [SETTING_DRAWER_TYPE]: (state, { payload, type }) => ({
      ...state,
      drawerType: payload,
      status: type,
    }),

    [SETTING_WINDOW_WIDTH]: (state, { payload, type }) => ({
      ...state,
      width: payload,
      status: type,
    }),

    [SETTING_THEME_COLOR]: (state, { payload, type }) => ({
      ...state,
      darkTheme: false,
      themeColor: payload,
      status: type,
    }),

    [SETTING_DARK_THEME]: (state, { payload, type }) => ({
      ...state,
      darkTheme: !state.darkTheme,
      status: type,
    }),

    [SETTING_CHANGE_NAVIGATION_STYLE]: (state, { payload, type }) => ({
      ...state,
      navigationStyle: payload,
      status: type,
    }),

    [SETTING_HORIZONTAL_MENU_POSITION]: (state, { payload, type }) => ({
      ...state,
      horizontalNavPosition: payload,
      status: type,
    }),

    [TOGGLE_HEADER_VISIBILITY]: (state, { payload, type }) => ({
      ...state,
      headerVisible: payload,
    }),
  },
  initialState,
);
