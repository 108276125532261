import { Airport } from 'models/Airport';
import { RootState } from 'store';
import { Moment } from 'moment';
import { DATE_RANGE_OPTIONS } from 'config/base';

type Range = {
  selected: keyof typeof DATE_RANGE_OPTIONS;
  inputRange: {
    start: Moment;
    end: Moment;
  };
  range: {
    current: {
      start: string;
      end: string;
      rawStart: Moment;
      rawEnd: Moment;
    };
    history: {
      start: string;
      end: string;
      rawStart: Moment;
      rawEnd: Moment;
    };
  };
};

type WidgetSettingsType<T> = {
  loading: boolean;
  dateRange: Range;
  mainField: number;
  sortBy: keyof T;
  sortDir: string;
  currentPage: number;
  urls?: {
    current: string;
  };
};

export const selectWidgetSettings = <T>(state: RootState, id: string) =>
  (
    state.dashboard.widgetSettings as {
      [key: string]: WidgetSettingsType<T> & { [key: string]: string | number | boolean };
    }
  )[id];

export const selectDateRange = (state: RootState) =>
  state.dashboard.dateRange.global as Range;

export const selectShowHistory = (state: RootState) => state.dashboard.showHistory;
export const selectHistoryParams = (state: RootState) => state.dashboard.historyParams;
export const selectAirports = (state: RootState) =>
  state.dashboard.airports as Record<string, Airport>;

export const selectInterval = (state: RootState) => state.dashboard.interval;
