import React, { useState, ReactNode } from 'react';
import AlertNotification from './AlertNotification';

interface AlertNotificationProps {
  children: ReactNode;
}

interface Alert {
  id: number;
  title: string;
  message: string;
  type: number;
  duration: number;
}

interface AlertNotificationContextInterface {
  notify: (message: string, title?: string, type?: number, duration?: number) => void;
}

export const AlertNotificationContext =
  React.createContext<AlertNotificationContextInterface>({
    notify: () => {},
  });

let idGen = 1;

export const ALERT_SUCCESS = 1;
export const ALERT_INFO = 2;
export const ALERT_WARNING = 3;
export const ALERT_ERROR = 4;

export const AlertNotificationProvider = ({ children }: AlertNotificationProps) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const closeAlert = (id: number) => {
    setAlerts((prev) => prev.filter((a) => a.id !== id));
  };

  const notify = (message: string, title = '', type = ALERT_INFO, duration = 3000) => {
    const id = idGen++;
    setAlerts((prev) =>
      prev.concat({
        id,
        title,
        message,
        type,
        duration,
      }),
    );

    setTimeout(() => {
      closeAlert(id);
    }, duration);
  };

  return (
    <AlertNotificationContext.Provider value={{ notify }}>
      {alerts.map((a) => {
        return (
          <AlertNotification
            key={a.id}
            title={a.title}
            message={a.message}
            type={a.type}
            onClose={() => closeAlert(a.id)}
          />
        );
      })}
      {children}
    </AlertNotificationContext.Provider>
  );
};
