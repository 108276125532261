import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Origin } from 'models/Origin';

interface OriginState {
  fetching: boolean;
  fetchingSingle: boolean;
  adding: boolean;
  updating: boolean;
  deleting: boolean;
  error: string | null;
  origins: Origin[];
  currentOrigin: Origin | null;
}

const initialState: OriginState = {
  fetching: false,
  fetchingSingle: false,
  adding: false,
  updating: false,
  deleting: false,
  error: null,
  origins: [],
  currentOrigin: null,
};

export const originsSlice = createSlice({
  name: 'origins',
  initialState,
  reducers: {
    fetchOrigins(state) {
      state.fetching = true;
    },
    fetchOriginsSuccess(state, action: PayloadAction<Origin[]>) {
      state.fetching = false;
      state.origins = action.payload;
      state.error = null;
    },
    fetchOriginsFail(state) {
      state.fetching = false;
      state.error = 'Failed to load origins';
    },
  },
});

export const {
  fetchOrigins,
  fetchOriginsSuccess,
  fetchOriginsFail,
} = originsSlice.actions;

export const reducer = originsSlice.reducer;
