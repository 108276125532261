import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DATE_RANGE_OPTIONS_STATS } from 'config/base';
import { Dayjs, ManipulateType } from 'dayjs';
import { RootState } from 'store';
import { getRangeStats, RangeParamStats } from 'utils/dates';
import { cleanCache } from 'utils/request';

type Range = {
  start: string;
  end: string;
  rawStart: Dayjs;
  rawEnd: Dayjs;
};

type HistoryParams = {
  amount: number;
  unit: ManipulateType;
};

type StatsRange = {
  selected: keyof typeof DATE_RANGE_OPTIONS_STATS;
  current: Range;
  history: Range;
};

interface State {
  range: StatsRange;
  historyParams: HistoryParams;
  refreshTime: number;
}

const initialState: State = {
  range: {
    selected: 'last7d',
    ...getRangeStats('last7d'),
  },
  historyParams: {
    amount: 7,
    unit: 'days',
  },
  refreshTime: 0,
};

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    setDateRangeStats(
      state,
      action: PayloadAction<{
        selected: keyof typeof DATE_RANGE_OPTIONS_STATS;
        range?: RangeParamStats;
      }>,
    ) {
      const { range, selected } = action.payload;
      const { amount, unit } = state.historyParams;

      const newRange = getRangeStats(selected, range, amount, unit);

      state.range = {
        selected,
        ...newRange,
      };
    },

    setHistoryParamsStats(
      state,
      action: PayloadAction<{
        amount: number;
        unit: ManipulateType;
      }>,
    ) {
      const { amount, unit } = action.payload;
      const {
        selected,
        current: { rawStart, rawEnd },
      } = state.range;

      const newRange = getRangeStats(
        selected,
        { start: rawStart, end: rawEnd },
        amount,
        unit,
      );

      state.range = {
        selected,
        ...newRange,
      };

      state.historyParams = {
        amount,
        unit,
      };
    },

    refreshStats(state) {
      cleanCache(true);
      const newRange = getRangeStats(
        state.range.selected,
        state.range.selected === 'custom'
          ? { start: state.range.current.rawStart, end: state.range.current.rawEnd }
          : undefined,
        state.historyParams.amount,
        state.historyParams.unit,
      );

      state.range = {
        selected: state.range.selected,
        ...newRange,
      };
      state.refreshTime = Date.now();
    },
  },
});

export const selectRangeStats = (state: RootState) => state.stats.range;
export const selectRefreshTime = (state: RootState) => state.stats.refreshTime;
export const selectHistoryAmount = (state: RootState) => state.stats.historyParams.amount;
export const selectHistoryUnit = (state: RootState) => state.stats.historyParams.unit;
export const { setDateRangeStats, setHistoryParamsStats, refreshStats } =
  statsSlice.actions;
export const reducer = statsSlice.reducer;
