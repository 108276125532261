import qs from 'query-string';
import recaptchaV3 from './recaptchaV3';

function parseContent(response: Response) {
  const contentType = response.headers.get('content-type');
  const isJson = contentType && contentType.indexOf('application/json') !== -1;

  if (isJson) {
    if (response.status !== 204) {
      return response.json();
    }
  }
  return response.text();
}

function checkStatus(response: Response) {
  if (response.ok) {
    return response;
  }

  const contentType = response.headers.get('Content-Type');

  const err = {
    status: response.status,
    statusText: response.statusText,
  };

  if (contentType?.includes('text/plain')) {
    return response.text().then((text) => {
      throw Object.assign(err, { message: text });
    });
  }

  return response.json().then(
    (json) => {
      throw Object.assign(err, {
        twoFactorAuthenticationRequired: json.twoFactorAuthenticationRequired,
        credentialOptions: json.credentialOptions,
        method: json.method,
        url: json.url,
        message:
          json.errors ||
          json.detail ||
          json.message ||
          (Array.isArray(json.body) && json.body[0].message),
      });
    },
    () => {
      throw Object.assign(err, { message: 'Failed to parse JSON' });
    },
  );
}

export function normalizeUrl(path: string) {
  if (/^(http|https):\/\//.test(path)) {
    return path;
  }
  return `${process.env.REACT_APP_NODEAPI_BASE_URL}${path}`;
}

type Data = object | string | undefined;

interface Options extends RequestInit {
  data?: Data;
  contentType?: string;
  acceptType?: string;
  token?: string;
  query?: Record<string, unknown>;
  useDefaultContentType?: boolean;
  withoutAuthorization?: boolean;
  withCaptcha?: boolean;
  action?: string;
  limit?: number;
  withCache?: boolean;
}

interface Auth {
  token: string;
}

export const abortControllers: Record<string, AbortController> = {};

type CachedResponse = {
  response: Promise<any>;
  time: number;
};

const CACHE_PERIOD = 1000 * 60 * 0.5; // 30s

const requestCache: Record<string, CachedResponse> = {};

export const cleanCache = (force = false) => {
  const currentDate = new Date().getTime();
  for (let key in requestCache) {
    if (force || currentDate - requestCache[key].time > CACHE_PERIOD) {
      delete requestCache[key];
    }
  }
};

export default async function request<T = any>(
  url: string,
  options: Options = {},
): Promise<T> {
  const controller = new AbortController();
  abortControllers[url] = controller;

  let newUrl = normalizeUrl(url);
  const contentType = options.contentType || 'application/json';
  const isJSON = contentType.indexOf('application/json') !== -1;

  const headers: HeadersInit = {
    Accept: options.acceptType || 'application/json',
    'Content-Type': contentType,
  };

  const sessionAuth = sessionStorage.getItem('auth');

  if (sessionAuth) {
    const auth: Auth = JSON.parse(sessionAuth);
    headers['x-cf-authorization'] = `Bearer ${auth.token}`;
  }

  if (options.token) {
    headers['x-cf-authorization'] = `Bearer ${options.token}`;
  }

  if (!options.method || options.method === 'GET') {
    if (options.query) {
      const queryString = qs.stringify(options.query);
      newUrl = `${newUrl}?${queryString}`;
    }
  }

  if (options.data) {
    options.body = isJSON ? JSON.stringify(options.data) : (options.data as string);
  }

  if (options.useDefaultContentType) {
    delete headers['Content-Type'];
  }

  if (options.withoutAuthorization) {
    delete headers.Authorization;
  }

  if (options.withCaptcha) {
    headers['X-CF-Captcha'] = await recaptchaV3(options.action || options.method);
  }

  options.headers = Object.assign({}, headers, options.headers);

  if (options.withCache) {
    if (
      requestCache[newUrl] &&
      new Date().getTime() - requestCache[newUrl].time < CACHE_PERIOD
    ) {
      return requestCache[newUrl].response;
    } else {
      delete requestCache[newUrl];
    }
  }

  const ret = fetch(newUrl, { ...options, signal: controller.signal }).then(checkStatus);

  const promise = ret
    .then(parseContent)
    .then((data) => data)
    .finally(() => {
      delete abortControllers[url];
    });

  if (options.withCache) {
    cleanCache();
    requestCache[newUrl] = {
      response: promise,
      time: new Date().getTime(),
    };
  }

  return promise;
}

export function apiPost<T = any>(
  url: string,
  data?: Data,
  options: Options = {},
): Promise<T> {
  return request<T>(url, {
    method: 'POST',
    data,
    ...options,
  });
}
export function apiPut<T = any>(
  url: string,
  data?: Data,
  options: Options = {},
): Promise<T> {
  return request<T>(url, {
    method: 'PUT',
    data,
    ...options,
  });
}

export function apiGet<T = any>(url: string, options: Options = {}): Promise<T> {
  return request<T>(url, {
    method: 'GET',
    ...options,
  });
}

export function apiDelete<T = any>(url: string, options: Options = {}): Promise<T> {
  return request<T>(url, {
    method: 'DELETE',
    ...options,
  });
}

type ActiveRequest = {
  url: string;
  promise: Promise<unknown>;
};

const activeRequests: ActiveRequest[] = [];
const cache: Record<string, any> = {};

function removeRequest(item?: ActiveRequest) {
  if (!item) return;
  const index = activeRequests.indexOf(item);
  if (index > -1) {
    //console.log('Removing request ', item.url);

    activeRequests.splice(index, 1);
  }
}

type ResponseWithPagination<T> = {
  data: T[];
  meta: {
    total: number;
    count: number;
  };
};

export async function autoPaginatedRequest<T = any>(url: string, options: Options) {
  const ret: ResponseWithPagination<T> = {
    data: [],
    meta: {
      total: 0,
      count: 0,
    },
  };
  let curOffset = 0,
    safety = 0;

  const limit = options.limit || 10000;

  do {
    const response = await request<ResponseWithPagination<T>>(
      `${url}&offset=${curOffset}&limit=${limit}`,
      options,
    );

    ret.data.push(...response.data);
    ret.meta = response.meta;
    safety++;

    const total = response.meta?.total || response.meta?.count;

    if (total > curOffset + limit) {
      curOffset += limit;
    } else {
      return ret;
    }
  } while (safety < 10);

  throw new Error(`${url} is returning ${safety * limit}+ records.`);
}

export function requestWithLock<T>(url: string, options: Options) {
  let item = activeRequests.find((r) => r.url === url);

  //console.log('requestWithLock ', url, cache);

  if (cache[url]) {
    console.log('CACHE HIT ', url);

    return cache[url];
  }

  if (!item) {
    //console.log('requestWithLock MISS ');
    const promise =
      url.indexOf('limit') >= 0
        ? request<T>(url, options)
        : autoPaginatedRequest<T>(url, options);

    promise
      // .then((data) => {
      //   console.log('Saving data in cache for url: ', url);
      //   cache[url] = data;
      // })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        removeRequest(item);
      });

    item = {
      url,
      promise,
    };
    activeRequests.push(item);
  } else {
    //console.log('requestWithLock HIT ');
  }

  return item.promise;
}
