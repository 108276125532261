import React, { CSSProperties, useRef } from 'react';
import cn from 'classnames';
import styles from './Spinner.module.scss';

interface SpinnerProps {
  main?: boolean;
  local?: boolean;
  overlayClassName?: string;
  icon?: boolean;
  spinnerStyle?: CSSProperties;
}

const Spinner = ({
  main = false,
  local = false,
  overlayClassName,
  icon = false,
  spinnerStyle = {},
}: SpinnerProps) => {
  const mountTime = useRef(Date.now());
  const mountDelay = -(mountTime.current % (main ? 2000 : 1200));
  const style = {
    '--spinner-delay': `${mountDelay}ms`,
    ...spinnerStyle,
  } as React.CSSProperties;

  if (main) {
    return (
      <div className={cn(styles.loadingWrapper, overlayClassName)}>
        <div className={styles.loadingText}>LOADING</div>
        <div className={styles.loadingContent} style={style}></div>
      </div>
    );
  }

  if (icon) {
    return <div className={styles.icon} style={style}></div>;
  }
  return (
    <div className={cn(styles.wrapper, local && styles.local)}>
      <div className={styles.spinner} style={style}></div>
    </div>
  );
};

export default Spinner;
