import React from 'react';
import { Icon } from './Icon';

const CloseIcon = ({ fill, ...rest }: Icon) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={fill || 'currentColor'}
        d="M8.51669 6.97703C8.09152 6.55187 7.40219 6.55187 6.97702 6.97703C6.55185 7.4022 6.55185 8.09154 6.97702 8.5167L10.2103 11.75L6.97702 14.9833C6.55185 15.4085 6.55185 16.0978 6.97702 16.523C7.40219 16.9481 8.09152 16.9481 8.51669 16.523L11.75 13.2897L14.9833 16.523C15.4085 16.9481 16.0978 16.9481 16.523 16.523C16.9481 16.0978 16.9481 15.4085 16.523 14.9833L13.2897 11.75L16.523 8.5167C16.9481 8.09153 16.9481 7.4022 16.523 6.97703C16.0978 6.55187 15.4085 6.55187 14.9833 6.97703L11.75 10.2103L8.51669 6.97703Z"
      />
    </svg>
  );
};

export default CloseIcon;
