import React, {
  createContext,
  MutableRefObject,
  ReactNode,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

interface WizardContextType {
  currentStep: number;
  setCurrentStep: (step: number | ((prev: number) => number)) => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  data: MutableRefObject<Record<string, Object | boolean>>;
}

export const WizardContext = createContext<WizardContextType>({} as WizardContextType);

interface WizardProviderProps {
  children: ReactNode;
}

export const WizardProvider = ({ children }: WizardProviderProps) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const data = useRef({});

  return (
    <WizardContext.Provider
      value={{ currentStep, setCurrentStep, isOpen, setIsOpen, data }}
    >
      {children}
    </WizardContext.Provider>
  );
};

WizardProvider.propTypes = {
  children: PropTypes.node,
};
