import {
  PayloadAction,
  SerializedError,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { Account } from 'models/Account';
import { RootState } from 'store';
import { apiGet } from 'utils/request';

export const getAccounts = createAsyncThunk(
  'accounts/getAccounts',
  async (_, thunkApi) => {
    const { data } = await apiGet<{ data: Account[] }>(
      '/accounts?isParent=false&responseType=full&limit=1000',
      {
        action: 'getAccounts',
      },
    );

    return data;
  },
);

export const getSingleAccount = createAsyncThunk(
  'accounts/getSingleAccount',
  async (id: string, thunkApi) => {
    const data = await apiGet<Account>(`/accounts/${id}?responseType=full`, {
      action: 'getAccounts',
    });

    return data;
  },
);

interface State {
  accounts: Account[];
  accountsFetched: boolean;
  selectedAccount: Account | null;
  isLoading: boolean;
  error: SerializedError | null;
  loadingSingle: boolean;
}

const initialState: State = {
  accounts: [],
  accountsFetched: false,
  selectedAccount: null,
  isLoading: false,
  error: null,
  loadingSingle: true,
};

const accountSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setSelectedAccount(state, action: PayloadAction<Account>) {
      state.selectedAccount = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAccounts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAccounts.fulfilled, (state, action: PayloadAction<Account[]>) => {
      state.isLoading = false;
      state.error = null;
      state.accounts = action.payload;
      state.accountsFetched = true;
    });
    builder.addCase(getAccounts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    builder.addCase(getSingleAccount.pending, (state, action) => {
      state.loadingSingle = true;
    });
    builder.addCase(
      getSingleAccount.fulfilled,
      (state, action: PayloadAction<Account>) => {
        state.loadingSingle = false;
        state.error = null;
        state.selectedAccount = action.payload;
      },
    );
    builder.addCase(getSingleAccount.rejected, (state, action) => {
      state.loadingSingle = false;
      state.error = action.error;
    });
  },
});

export const selectAccounts = (state: RootState) => state.accounts.accounts;

export const selectAccountsWithPlaceholder = createSelector(selectAccounts, (accounts) =>
  [{ _id: 'all', companyName: '(All Accounts)' } as Account].concat(accounts),
);

export const selectAccountsLoading = (state: RootState) => state.accounts.isLoading;
export const selectAccountsError = (state: RootState) => state.accounts.error;
export const selectSelectedAccount = (state: RootState) => state.accounts.selectedAccount;
export const selectAccountsFetched = (state: RootState) => state.accounts.accountsFetched;
export const selectSingleAccountLoading = (state: RootState) =>
  state.accounts.loadingSingle;

export const setSelectedAccount = accountSlice.actions.setSelectedAccount;

export const reducer = accountSlice.reducer;
