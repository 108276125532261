// Customizer const
export const SETTING_DRAWER_TYPE = 'DRAWER_TYPE'
export const SETTING_COLLAPSED_DRAWER = 'COLLAPSED_DRAWER'
export const SETTING_FIXED_DRAWER = 'FIXED_DRAWER'
export const SETTING_MINI_DRAWER = 'MINI_DRAWER'

export const SETTING_DARK_THEME = 'DARK_THEME'

export const SETTING_THEME_COLOR = 'THEME_COLOR'

export const SETTING_WINDOW_WIDTH = 'WINDOW_WIDTH'

export const SETTING_CHANGE_NAVIGATION_STYLE = 'CHANGE_NAVIGATION_STYLE'
export const SETTING_TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV'
export const SETTING_HORIZONTAL_NAVIGATION = 'HORIZONTAL_NAVIGATION'
export const SETTING_VERTICAL_NAVIGATION = 'VERTICAL_NAVIGATION'

export const SETTING_HORIZONTAL_MENU_POSITION = 'HORIZONTAL_MENU_POSITION'
export const SETTING_ABOVE_THE_HEADER = 'ABOVE_THE_HEADER'
export const SETTING_BELOW_THE_HEADER = 'BELOW_THE_HEADER'
export const SETTING_INSIDE_THE_HEADER = 'INSIDE_THE_HEADER'

export const TOGGLE_HEADER_VISIBILITY = 'TOGGLE_HEADER_VISIBILITY';
