import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';

export const PageLeavePromptContext = createContext(null);

const PageLeavePromptProvider = ({ children }) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [message, setLeavePromptMessage] = useState(
    'You have unsaved changes, are you sure you want to navigate to another page?',
  );
  const [allowNavigation, setAlloweNavigation] = useState(true);

  const checkIfThereAreChanges = useCallback(() => {
    if (hasChanges instanceof Function) {
      return hasChanges();
    }
    return hasChanges;
  }, [hasChanges]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (checkIfThereAreChanges()) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [checkIfThereAreChanges]);

  const askChanges = useCallback(
    (flag) => {
      if (flag instanceof Function) {
        setHasChanges(() => flag);
      } else {
        setHasChanges(flag);
      }
    },
    [hasChanges],
  );

  const resetPromptMessage = () => {
    setLeavePromptMessage(
      'You have unsaved changes, are you sure you want to navigate to another page?',
    );
  };
  return (
    <PageLeavePromptContext.Provider
      value={{
        askChanges,
        setLeavePromptMessage,
        setAlloweNavigation,
        resetPromptMessage,
      }}
    >
      <Prompt
        message={(location) => {
          if (allowNavigation instanceof Function) {
            return allowNavigation(location.pathname) ? true : message;
          }
          if (checkIfThereAreChanges()) {
            return message;
          }
        }}
      />
      {children}
    </PageLeavePromptContext.Provider>
  );
};

PageLeavePromptProvider.propTypes = {
  children: PropTypes.node,
};

export default PageLeavePromptProvider;
