declare global {
  interface Window {
    dataLayer?: Record<string, unknown>[];
  }
}

export const triggerEvent = ({ name }: { name: string }) => {
  window.dataLayer?.push({
    event: name,
  });
};

export const loadGA = () => {
  const gtmScript = document.createElement('script');
  const gtagScript = document.createElement('script');
  gtmScript.defer = true;
  gtagScript.defer = true;
  gtmScript.innerHTML = `(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-NDX3XT7X')`;
  gtagScript.innerHTML = `window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-DR97N4P8NG');`;

  document.head.appendChild(gtmScript);
  document.head.appendChild(gtagScript);
};
