import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import ConfirmationDialog, { DialogType } from './ConfirmationDialog';

export type DialogConfigProps = {
  title?: string;
  message: ReactNode;
  confirmBtnText?: string;
  cancelBtnText?: string;
  type?: DialogType;
};

export type DialogConfig = DialogConfigProps & {
  actionCallback: (value: boolean) => void;
};

type ConfirmationDialogState = {
  openDialog: (dialog: DialogConfig) => void;
};

export const ConfirmationDialogContext = React.createContext(
  {} as ConfirmationDialogState,
);

export const ConfirmationDialogProvider = ({ children }: PropsWithChildren) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<DialogConfig>({} as DialogConfig);

  const openDialog = useCallback((dialogConfig: DialogConfig) => {
    setDialogOpen(true);
    setDialogConfig(dialogConfig);
  }, []);

  const resetDialog = useCallback(() => {
    setDialogOpen(false);
    setDialogConfig({} as DialogConfig);
  }, []);

  const onConfirm = useCallback(() => {
    resetDialog();
    dialogConfig.actionCallback(true);
  }, [dialogConfig, resetDialog]);

  const onDismiss = useCallback(() => {
    resetDialog();
    dialogConfig.actionCallback(false);
  }, [dialogConfig, resetDialog]);

  const contextValue = useMemo(
    () => ({
      openDialog,
    }),
    [],
  );

  return (
    <ConfirmationDialogContext.Provider value={contextValue}>
      <ConfirmationDialog
        open={dialogOpen}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        confirmBtnText={dialogConfig?.confirmBtnText}
        cancelBtnText={dialogConfig?.cancelBtnText}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        type={dialogConfig.type}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};
