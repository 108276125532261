import { load } from 'recaptcha-v3';

async function recaptchaV3(action: string = 'action') {
  const recaptcha = await load(process.env.REACT_APP_RECAPTCHA_V3!, {
    autoHideBadge: true,
  });
  const token = await recaptcha.execute(action);
  return `gv3:${window.location.hostname}:${action}:${token}`;
}

export default recaptchaV3;
