import React from 'react';
import { Icon } from './Icon';

const WarningIcon = ({ fill = 'white', ...rest }: Icon) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill={fill || 'currentColor'}
        fillRule="evenodd"
        d="M13.6488 5.32441C13.6488 4.59296 13.0559 4 12.3244 4C11.593 4 11 4.59296 11 5.32441V13.2746C11 14.006 11.593 14.599 12.3244 14.599C13.0559 14.599 13.6488 14.006 13.6488 13.2746V5.32441ZM12.3244 17.6812C11.593 17.6812 11 18.2741 11 19.0056C11 19.737 11.593 20.33 12.3244 20.33H12.3495C13.081 20.33 13.6739 19.737 13.6739 19.0056C13.6739 18.2741 13.081 17.6812 12.3495 17.6812H12.3244Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default WarningIcon;
