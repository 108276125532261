export const loadHappierLeads = () => {
  const happierleadsScript = document.createElement('script');
  happierleadsScript.defer = true;
  happierleadsScript.innerHTML = `!function(){var e="rest.happierleads.com/v3/script?clientId=vc6SCmySNMZb45qLvPdmD3&version=4.0.0",
    t=document.createElement("script");window.location.protocol.split(":")[0];
    t.src="https://"+e;var c=document.getElementsByTagName("script")[0];
    t.async = true;
    t.onload = function(){ new Happierleads.default };
    c.parentNode.insertBefore(t,c)}();
      `;
  document.head.appendChild(happierleadsScript);
};
