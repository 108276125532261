import React from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import { ALERT_ERROR, ALERT_SUCCESS } from './AlertNotificationProvider';
import CheckIcon from 'design/Icons/CheckIcon';
import CloseIcon from 'design/Icons/CloseIcon';
import InfoLetterIcon from 'design/Icons/InfoLetterIcon';
import WarningIcon from 'design/Icons/WarningIcon';

interface AlertNotificationProps {
  title: string;
  message: string;
  type: number;
  onClose: () => void;
}

const AlertNotification = ({ title, message, type, onClose }: AlertNotificationProps) => {
  return createPortal(
    <div className="alert-area">
      <div
        className={cx('alert alert-success alert-dismissible fade show', {
          'alert-success': type === ALERT_SUCCESS,
          'alert-danger': type === ALERT_ERROR,
        })}
        role="alert"
      >
        <div className="alert-icon">
          <span>
            {type === ALERT_SUCCESS ? (
              <InfoLetterIcon />
            ) : type === ALERT_ERROR ? (
              <CheckIcon />
            ) : (
              <WarningIcon />
            )}
          </span>
        </div>
        <div className="alert-message">
          <h5>{title}</h5>
          {message}
        </div>
        <div className="alert-actions">
          <button
            type="button"
            className="btn-alert-close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={onClose}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div>,
    document.getElementById('alert-area-root')!,
  );
};

export default AlertNotification;
