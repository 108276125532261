import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchCertificatesFail,
  fetchCertificatesSuccess,
  fetchSingleCertificateFail,
  fetchSingleCertificateSuccess,
} from './reducer';
import { CERTIFICATES_GET, CERTIFICATES_GET_SINGLE } from './constants';
import request from 'utils/request';

const doFetchCertificates = function* ({ payload = {} }) {
  const { limit = 1000 } = payload;

  const requestURL = `/certificates?responseType=full&limit=${limit}`;
  const param = {
    method: 'GET',
    bearer: true,
  };

  try {
    const { data } = yield call(request, requestURL, param);
    yield put(fetchCertificatesSuccess(data));
  } catch (error) {
    toast.error('An error occurred while trying to fetch certificate list');
    yield put(fetchCertificatesFail(error));
  }
};

const doFetchSingleCertificate = function* (action) {
  const requestURL = `/certificates/${action.payload}?responseType=full`;
  const param = {
    method: 'GET',
    bearer: true,
  };

  try {
    const res = yield call(request, requestURL, param);
    yield put(fetchSingleCertificateSuccess(res));
  } catch (error) {
    yield put(fetchSingleCertificateFail(error));
  }
};

export const saga = function* () {
  yield takeEvery(CERTIFICATES_GET, doFetchCertificates);
  yield takeEvery(CERTIFICATES_GET_SINGLE, doFetchSingleCertificate);
};
