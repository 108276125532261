import { useAppDispatch, useAppSelector } from './useStore';
import { selectUser, userSignInSuccess } from 'store/modules/auth';
import { User } from 'models/User';

const useCurrentUser = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const updateUser = (newUser: User) => {
    const auth = sessionStorage.getItem('auth');
    if (auth) {
      const json: { token: string; user: User } = JSON.parse(auth);
      sessionStorage.setItem(
        'auth',
        JSON.stringify({ user: newUser, token: json.token }),
      );
      dispatch(userSignInSuccess(newUser));
    }
  };

  return { user, updateUser };
};

export default useCurrentUser;
