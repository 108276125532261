// Dashboard

export const DASH_WIDGET_DATE_RANGE_CHANGE = 'DASH_WIDGET_DATE_RANGE_CHANGE';
export const DASH_WIDGET_AUTO_REFRESH_CHANGE = 'DASH_WIDGET_AUTO_REFRESH_CHANGE';

export const DASH_GLOBAL_AUTO_REFRESH_CHANGE = 'DASH_GLOBAL_AUTO_REFRESH_CHANGE';

export const DASH_FETCH_SINGLE_WIDGET_DATA = 'DASH_FETCH_SINGLE_WIDGET_DATA';

export const DASH_INIT_WIDGET = 'DASH_INIT_WIDGET';
export const DASH_DESTROY_WIDGET = 'DASH_DESTROY_WIDGET';
export const DASH_GLOBAL_WIDGET_DATE_RANGE_CHANGE =
  'DASH_GLOBAL_WIDGET_DATE_RANGE_CHANGE';

export const DASH_WIDGET_REFRESH = 'DASH_WIDGET_REFRESH';
export const DASH_WIDGET_UPDATE_SETTINGS = 'DASH_WIDGET_UPDATE_SETTINGS';
export const DASH_GLOBAL_WIDGET_REFRESH = 'DASH_GLOBAL_WIDGET_REFRESH';

export const DASH_SET_SHOW_HISTORY = 'DASH_SET_SHOW_HISTORY';
export const DASH_SET_HISTORY_PARAMS = 'DASH_SET_HISTORY_PARAMS';

export const DASH_AIRPORTS_GET = 'DASH_AIRPORTS_GET';

export const DASH_SET_INTERVAL = 'DASH_SET_INTERVAL';
