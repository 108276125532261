import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import Routes from './routes';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/app.scss';
import 'styles/mirna/new-portal.scss';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { ConfirmationDialogProvider } from './components/ConfirmationDialog/ConfirmationDialogProvider';
import { AlertNotificationProvider } from './components/AlertNotifications/AlertNotificationProvider';
import { WizardProvider } from './containers/Pages/ServiceLandingPage/components/Wizard/WizardProvider';
import { loadStonlyWidget } from 'utils/stonly';
import { loadGA } from 'utils/googleAnalytics';
import { loadHappierLeads } from 'utils/happierLeads';

if (process.env.REACT_APP_ENV === 'production') {
  loadGA();
  loadHappierLeads();

  datadogLogs.init({
    clientToken: 'pub21c630f4fb8e5e31dfaddfa44f220d77',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env: process.env.REACT_APP_ENV,
  });

  datadogRum.init({
    applicationId: 'b6aae8a8-ac4f-4380-8a7e-9d62b054a935',
    clientToken: 'pub21c630f4fb8e5e31dfaddfa44f220d77',
    site: 'datadoghq.com',
    service: 'newportal',
    env: process.env.REACT_APP_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  // Disable console.log in production
  console.log = function () {};
}

loadStonlyWidget();

toast.configure({
  autoClose: 1500,
  hideProgressBar: true,
  theme: 'colored',
});

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ConfirmationDialogProvider>
      <AlertNotificationProvider>
        <WizardProvider>
          <Routes />
        </WizardProvider>
      </AlertNotificationProvider>
    </ConfirmationDialogProvider>
  </Provider>,
  // </React.StrictMode>,
);
