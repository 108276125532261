import { createAction, handleActions, Action } from 'redux-actions';
import { successAction, failAction } from 'utils/state-helpers';
import {
  ORIGINS_UPDATE,
  ORIGINS_DELETE,
  ORIGINS_GET,
  ORIGINS_GET_SINGLE,
} from './constants';

/**
 * Action creators
 */
// export const fetchOrigins = createAction(ORIGINS_GET);
// export const fetchOriginsSuccess = createAction(successAction(ORIGINS_GET));
// export const fetchOriginsFail = createAction(failAction(ORIGINS_GET));

export const fetchSingleOrigin = createAction(ORIGINS_GET_SINGLE);
export const fetchSingleOriginSuccess = createAction(successAction(ORIGINS_GET_SINGLE));
export const fetchSingleOriginFail = createAction(failAction(ORIGINS_GET_SINGLE));

export const updateOrigin = createAction(ORIGINS_UPDATE);
export const updateOriginSuccess = createAction(successAction(ORIGINS_UPDATE));
export const updateOriginFail = createAction(failAction(ORIGINS_UPDATE));

export const deleteOrigin = createAction(ORIGINS_DELETE);
export const deleteOriginSuccess = createAction(successAction(ORIGINS_DELETE));
export const deleteOriginFail = createAction(failAction(ORIGINS_DELETE));

/**
 * Initial state
 */

type Origin = {
  _id: string;
  updateAt: string;
  createdAt: string;
  name: string;
  hostname: string;
  cacheByQueryParam: boolean;
  gzip: boolean;
  scheme: string;
  ttl: number;
  missedTtl: number;
};

type State = {
  fetching: boolean;
  fetchingSingle: boolean;
  adding: boolean;
  updating: boolean;
  deleting: boolean;
  error: string | null;
  origins: Origin[];
  currentOrigin: Origin | null;
};

const initialState: State = {
  fetching: false,
  fetchingSingle: false,
  adding: false,
  updating: false,
  deleting: false,
  error: null,
  origins: [],
  currentOrigin: null,
};

/**
 * Reducer
 */

const reducer = handleActions<State, any>( // eslint-disable-line
  {
    [ORIGINS_GET]: (state) => ({
      ...state,
      fetching: true,
    }),

    [successAction(ORIGINS_GET)]: (state, action: Action<Origin[]>) => ({
      ...state,
      fetching: false,
      origins: action.payload,
    }),

    [ORIGINS_GET_SINGLE]: (state) => ({
      ...state,
      fetchingSingle: true,
      error: null,
    }),

    [failAction(ORIGINS_GET_SINGLE)]: (state) => ({
      ...state,
      fetchingSingle: false,
      error: 'Failed to load origin data',
    }),

    [successAction(ORIGINS_GET_SINGLE)]: (state, action: Action<Origin>) => ({
      ...state,
      fetchingSingle: false,
      currentOrigin: action.payload,
    }),

    [ORIGINS_UPDATE]: (state) => ({
      ...state,
      updating: true,
      error: null,
    }),

    [successAction(ORIGINS_UPDATE)]: (state) => ({
      ...state,
      updating: false,
      error: null,
    }),

    [failAction(ORIGINS_UPDATE)]: (state) => ({
      ...state,
      updating: false,
      error: 'Failed to update origin',
    }),

    [ORIGINS_DELETE]: (state) => ({
      ...state,
      deleting: true,
      error: null,
    }),

    [successAction(ORIGINS_DELETE)]: (state, action: Action<{ id: string }>) => ({
      ...state,
      origins: state.origins.filter((h) => h._id !== action.payload.id),
      deleting: false,
      error: null,
    }),

    [failAction(ORIGINS_DELETE)]: (state) => ({
      ...state,
      deleting: false,
      error: 'Failed to delete origin',
    }),
  },
  initialState,
);
